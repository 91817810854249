<template>
    <div class="box">
        <h2>
            PDF Formular
            <small v-if="pdfForm.id != ''">#{{ pdfForm.id }}</small>
        </h2>
        <form @submit.prevent="save()">
            <div class="body">
                <div class="form-group form-float">
                    <input v-model="pdfForm.label" v-validate="'required'" id="label" name="label" placeholder=" " type="text" />
                    <label for="label">Bezeichner</label>
                    <span class="error" v-show="errors.has('label')">{{ errors.first("label") }}</span>
                </div>
            </div>
            <h2>Seite hinzufügen</h2>
            <div class="body">
                <div class="cols">
                    <div class="form-group form-float">
                        <input v-model="newpage.label" id="newpage_label" name="newpage_label" placeholder=" " type="text" />
                        <label for="newpage_label">Bezeichner</label>
                        <span class="error" v-show="errors.has('newpage_label')">{{ errors.first("newpage_label") }}</span>
                    </div>
                    <div class="form-group form-float">
                        <input v-on:change="selectNewpageFile()" ref="newpageFile" accept="application/pdf" id="newpage_file" name="newpage_file" placeholder=" " type="file" />
                        <label for="newpage_file">PDF Datei</label>
                        <span class="error" v-show="errors.has('newpage_file')">{{ errors.first("newpage_file") }}</span>
                    </div>
                    <button type="button" class="btn primary size-l" @click.prevent="addPage()"><i class="fal fa-plus"></i> Hinzufügen</button>
                </div>
            </div>
            <h2>Seiten</h2>
            <div class="body">
                <div v-for="(page, pageidx) in pdfForm.pages" v-bind:key="page.id">
                    <h3 class="group-header" @click="toggleOpen(page.id)" :class="{ open: pageOpen(page.id) }">
                        <span>{{ page.label }}</span>
                        <div class="toolbar">
                            <i class="fal fa-arrow-up" @click.stop="pageUp(pageidx)"></i>
                            <i class="fal fa-arrow-down" @click.stop="pageDown(pageidx)"></i>
                            <i class="fal fa-trash-alt" @click.stop="pageDelete(pageidx)"></i>
                        </div>
                    </h3>

                    <div v-show="pageOpen(page.id)" class="cols">
                        <span>&nbsp;</span>
                        <div class="form-group form-float">
                            <input v-on:change="selectUpdatedPageFile(pageidx, $event)" accept="application/pdf" :id="page.id + '_file'" :name="page.id + '_file'" placeholder=" " type="file" />
                            <label :for="page.id + '_file'">PDF Datei</label>
                            <br /><span class="small"
                                >Dateiname: <a :href="downloadURL(page.id)">{{ page.originalName }}</a></span
                            >
                        </div>
                        <button type="button" class="btn primary size-l" @click.prevent="refreshPage(pageidx)"><i class="fal fa-sync-alt"></i> Aktualisieren</button>
                    </div>

                    <h4 v-show="pageOpen(page.id)">Stammdaten</h4>

                    <div v-show="pageOpen(page.id)" class="cols cols-equal">
                        <div class="form-group form-float">
                            <input v-model="page.label" :id="page.id + '_label'" :name="page.id + '_label'" placeholder=" " type="text" />
                            <label :for="page.id + '_label'">Bezeichner</label>
                            <span class="error" v-show="errors.has(page.id + '_label')">{{ errors.first(page.id + "_label") }}</span>
                        </div>
                        <product-query-assist :product="pdfForm.product" v-model="page.condition" />
                    </div>
                    <div v-show="pageOpen(page.id)" class="form-group form-float">
                        <input type="checkbox" v-model="page.addQRCode" :id="page.id + '_addQRCode'" :name="page.id + '_addQRCode'" />
                        <label :for="page.id + '_addQRCode'">QR Code auf Seite einbinden</label>
                    </div>

                    <h4 v-show="pageOpen(page.id)">Felder</h4>

                    <div v-show="pageOpen(page.id)" v-for="(field, index) in page.fields" v-bind:key="index" class="form-group form-float form-select">
                        <product-content-assist :product="pdfForm.product" v-model="field.mapping" :vars="currentVars">{{ field.field }}</product-content-assist>
                    </div>
                </div>
            </div>
            <h2>Variablen</h2>
            <div class="body">
                <table class="customFields">
                    <tbody>
                        <tr v-for="(field, index) in pdfForm.customFields" v-bind:key="index">
                            <td>
                                <div class="form-group form-float no-error">
                                    <input v-model="field.id" v-validate="'required'" :id="'field_id_' + index" :name="'field_id_' + index" placeholder=" " type="text" />
                                    <label :for="'field_id_' + index">ID</label>
                                    <span class="error" v-show="errors.has('field_id_' + index)">{{ errors.first("field_id_" + index) }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="form-group form-float no-error">
                                    <input v-model="field.label" v-validate="'required'" :id="'field_label_' + index" :name="'field_label_' + index" placeholder=" " type="text" />
                                    <label :for="'field_label_' + index">Bezeichner</label>
                                    <span class="error" v-show="errors.has('field_label_' + index)">{{ errors.first("field_label_" + index) }}</span>
                                </div>
                            </td>
                            <td class="subtable">
                                <table>
                                    <tbody>
                                        <tr v-for="(entry, cidx) in field.content" v-bind:key="cidx">
                                            <td>
                                                <product-content-assist :product="pdfForm.product" v-model="entry.content" :vars="currentVars">Inhalt</product-content-assist>
                                            </td>
                                            <td>
                                                <product-query-assist :product="pdfForm.product" v-model="entry.condition" />
                                            </td>
                                            <td class="remove"><i @click="removeFieldContent(field, cidx)" class="delete fal fa-trash-alt"></i></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <span class="add" @click="addFieldContent(field)"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="remove"><i @click="removeField(index)" class="delete fal fa-trash-alt"></i></td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="add" @click="addField()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr />

                <div class="button-row">
                    <button type="submit" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Vue from "vue";
import ProductQueryAssist from "../../components/widgets/ProductQueryAssist";
import ProductContentAssist from "../../components/widgets/ProductContentAssist";

export default {
    data() {
        return {
            test: "",
            openPages: {},
            newpage: {
                file: null,
                label: ""
            },
            refreshPages: {},
            pdfForm: {
                id: "",
                product: "",
                label: "",
                pages: [],
                customFields: []
            }
        };
    },
    computed: {
        apiBase() {
            return "/docs/category/" + this.$route.params.product + "/pdfform/" + this.$route.params.id;
        },
        currentVars() {
            let vars = [];
            for (let i = 0; i < this.pdfForm.customFields.length; i++) {
                vars.push({
                    id: "vars:" + this.pdfForm.customFields[i].id,
                    label: this.pdfForm.customFields[i].label
                });
            }

            return vars;
        }
    },
    methods: {
        downloadURL(pageid) {
            return process.env.VUE_APP_API + this.apiBase + "/page/" + pageid;
        },
        selectUpdatedPageFile(index, event) {
            this.refreshPages[index] = event.target.files[0];
        },
        refreshPage(index) {
            let data = new FormData();
            data.append("body", "{}");
            data.append("page", this.refreshPages[index]);

            // Refresh
            this.$api.put(this.apiBase + "/page/" + this.pdfForm.pages[index].id, data).then(
                () => {
                    // Event updated
                    this.$snotify.success("Dokument aktualisiert");
                    this.fetchData();
                },
                () => {
                    // Update failed
                    this.$snotify.error("Aktualisierung fehlgeschlagen");
                }
            );
        },
        pageUp(index) {
            if (index === 0) {
                // Cannot go up
                return;
            }
            this.swapPages(index - 1, index);
        },
        pageDown(index) {
            if (index === this.pdfForm.pages.length - 1) {
                // Cannot go down
                return;
            }
            this.swapPages(index + 1, index);
        },
        swapPages(x, y) {
            var tmp = this.pdfForm.pages[x];
            this.pdfForm.pages[x] = this.pdfForm.pages[y];
            Vue.set(this.pdfForm.pages, y, tmp);
        },
        performPageDelete(index) {
            this.$api.delete(this.apiBase + "/page/" + this.pdfForm.pages[index].id).then(
                () => {
                    // Event deleted
                    this.$snotify.success("Pdf Seite gelöscht");
                    this.pdfForm.pages.splice(index, 1);
                },
                () => {
                    // Delete failed
                    this.$snotify.error("Löschen fehlgeschlagen");
                }
            );
        },
        pageDelete(index) {
            this.$snotify.confirm("PDF Seite dauerhaft löschen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performPageDelete(index);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        pageOpen(obj) {
            if (typeof this.openPages[obj] === "undefined") {
                this.$set(this.openPages, obj, false);
                this.openPages[obj] = false;
            }

            return this.openPages[obj];
        },
        toggleOpen(obj) {
            this.$set(this.openPages, obj, !this.openPages[obj]);
        },
        addPage() {
            let data = new FormData();
            data.append("body", JSON.stringify({ label: this.newpage.label }));
            data.append("page", this.newpage.file);

            this.$api.post(this.apiBase + "/page", data).then(
                () => {
                    // Event updated
                    this.$snotify.success("Document updated");
                    this.newpage = {};
                    this.fetchData();
                },
                () => {
                    // Update failed
                    this.$snotify.error("Document update failed");
                }
            );
        },
        selectNewpageFile() {
            this.newpage.file = this.$refs.newpageFile.files[0];
        },
        edit() {
            // Build pages input
            var pages = [];
            for (var i = 0; i < this.pdfForm.pages.length; i++) {
                var fields = [];
                for (var f = 0; f < this.pdfForm.pages[i].fields.length; f++) {
                    fields.push({
                        field: this.pdfForm.pages[i].fields[f].field,
                        mapping: this.pdfForm.pages[i].fields[f].mapping
                    });
                }
                pages.push({
                    id: this.pdfForm.pages[i].id,
                    condition: this.pdfForm.pages[i].condition,
                    label: this.pdfForm.pages[i].label,
                    addQRCode: this.pdfForm.pages[i].addQRCode,
                    fields: fields
                });
            }
            var customFields = [];
            for (var cf = 0; cf < this.pdfForm.customFields.length; cf++) {
                var content = [];
                for (var c = 0; c < this.pdfForm.customFields[cf].content.length; c++) {
                    content.push({
                        condition: this.pdfForm.customFields[cf].content[c].condition,
                        content: this.pdfForm.customFields[cf].content[c].content
                    });
                }
                customFields.push({
                    label: this.pdfForm.customFields[cf].label,
                    id: this.pdfForm.customFields[cf].id,
                    content: content
                });
            }

            let request = {
                product: this.pdfForm.product,
                label: this.pdfForm.label,
                pages: pages,
                customFields: customFields
            };

            this.$api.put(this.apiBase, request).then(
                () => {
                    // Template created
                    this.$snotify.success("PDF Form aktualisiert");
                },
                () => {
                    // Update failed
                    this.$snotify.error("Aktualisierung fehlgeschlagen");
                }
            );
        },
        save() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                this.edit();
            });
        },
        addField() {
            this.pdfForm.customFields.push({ label: "", content: [{ content: "", condition: "" }] });
        },
        removeField(idx) {
            this.pdfForm.customFields.splice(idx, 1);
        },
        addFieldContent(field) {
            field.content.push({ content: "", condition: "" });
        },
        removeFieldContent(field, idx) {
            field.content.splice(idx, 1);
        },
        fetchData() {
            this.$api.get(this.apiBase).then(
                response => {
                    if (response.data.data.pages === null) {
                        response.data.data.pages = [];
                    }
                    if (response.data.data.customFields === null) {
                        response.data.data.customFields = [];
                    }

                    this.pdfForm = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden des Formulars");
                }
            );
        }
    },
    created() {
        this.fetchData();
    },
    components: {
        ProductQueryAssist,
        ProductContentAssist
    }
};
</script>

<style lang="scss" scoped>
table.customFields {
    > tbody > tr:first-child td {
        border-top: 0;
    }

    td {
        vertical-align: top;

        table td {
            border-top: 0;
        }

        &.remove {
            vertical-align: middle;
            border-left: 1px solid #e5ebec;
        }

        &.subtable {
            padding: 0;
        }
    }
}

div.cols {
    display: flex;
    align-items: flex-start;

    :first-child {
        flex-grow: 1;
        margin-right: 1rem;
    }

    &.cols-equal {
        > * {
            flex-grow: 1;
        }
    }
}

h3.group-header {
    display: flex;
    span {
        flex-grow: 1;
    }
    div.toolbar {
        display: inline;

        i {
            cursor: pointer;
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

span.small {
    font-size: 0.7rem;
    line-height: 1rem;
}
</style>
